<template>
  <div class="untrated tableBox">
    <el-table height="55vh" :row-class-name="tableRowClassName" :append-to-body="true"
              @row-click="rowClick" :row-style="selectedHighlight"
              :data="dataList" border size="small"
              v-loading="dataListLoading"  style="width: 100%;">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="noticeContent" label="标题">
          </el-table-column>
          <el-table-column prop="createName" label="发布人" :show-overflow-tooltip="true" width="80">
          </el-table-column>
          <el-table-column prop="noticeTime" label="提醒时间" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="optProcessed(scope.row)">已处理</el-button>
            </template>
        </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: true,
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    optProcessed (row) {
      // this.$confirm(`确定对该条数据进行【已读】操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      this.$http({
        url: this.$http.adornUrl(`/sysNotice/updateStatus/${row.id}`),
        method: 'post'
      }).then(({ data }) => {
        if (data) {
          this.$message.success('标记成功')
          this.getDataList()
          this.$http({
            url: this.$http.adornUrl('/sysNotice/count'),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data > 0) {
              this.$store.commit('common/updateNotesCount', data)
            }
          })
        }
      })
      // })
    },
    // 获取数据列表
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/sysNotice/findList'),
        method: 'get',
        params: this.$http.adornParams({
          status: 2,
          'page': this.pageIndex,
          'limit': this.pageSize
        })
      }).then(({ data }) => {
        if (data.total) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 1
        }
      })
    }
  }
}
</script>
