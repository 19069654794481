<template>
   <div>
    <el-dialog title="待办事项" :close-on-click-modal="false"
               width="58%" :visible.sync="visible"
               size="mini"
               :modal-append-to-body='false' class="memorandum">
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="未处理" name="未处理">
              <untreated ref="untreated"></untreated>
            </el-tab-pane>
            <el-tab-pane label="已处理" name="已处理">
              <processed ref="processed"></processed>
            </el-tab-pane>
        </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" type="danger">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import processed from './notesDetail/processed'
import untreated from './notesDetail/untreated'
export default {
  data () {
    return {
      visible: false,
      activeName: '未处理'
    }
  },
  components: {
    processed,
    untreated
  },
  methods: {
    close () {
      this.visible = false
    },
    init () {
      let that = this
      this.visible = true
      this.activeName = '未处理'
      setTimeout(function () {
        that.$refs.untreated.getDataList()
      })
    },
    handleClick () {
      if (this.activeName === '未处理') {
        this.$refs.untreated.getDataList()
      } else {
        this.$refs.processed.getDataList()
      }
    }
  }
}
</script>
<style lang="less">

</style>
